import React from "react";
import AboutSection from "../Components/AboutSection";
import MeetCEOSection from "../Components/Team";

const About = () => {
  return (
    <div className="py-20">
      <AboutSection />
      <MeetCEOSection />
    </div>
  );
};

export default About;
