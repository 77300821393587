import React, { useState, useEffect } from "react";

const testimonials = [
  {
    name: "Abebe Tadesse",
    position: "Marketing Director, Addis Media",
    feedback:
      "Energy Film Production brought our vision to life with exceptional storytelling and professionalism.",
    rating: 5,
    project: "Documentary Series",
  },
  {
    name: "Lensa Mekonnen",
    position: "CEO, Innovative Ethiopia",
    feedback:
      "Their dedication to quality and creativity made our campaign a huge success!",
    rating: 5,
    project: "Commercial Campaign",
  },
  {
    name: "Mulugeta Bekele",
    position: "Creative Director, Afro Arts Studio",
    feedback:
      "Highly professional team! The final production exceeded our expectations.",
    rating: 4,
    project: "Music Video Production",
  },
];

const TestimonialsSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        setIsAnimating(false);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, i) => (
        <svg
          key={i}
          className={`w-5 h-5 ${i < rating ? "text-yellow-400" : "text-gray-600"}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
        </svg>
      ));
  };

  return (
    <section className="py-20 bg-gray-900 text-white relative overflow-hidden">
      <div className="container mx-auto px-6 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold mb-4">What Our Clients Say</h2>
          <div className="w-24 h-1 bg-yellow-600 mx-auto mb-8"></div>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Don't just take our word for it. Here's what our Ethiopian clients have to say about their experience working with us.
          </p>
        </div>

        <div className="mb-16">
          <div 
            className={`max-w-4xl mx-auto bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-2xl shadow-2xl transform transition-all duration-500 ${isAnimating ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}
          >
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="relative">
                <div className="w-28 h-28 md:w-36 md:h-36 flex items-center justify-center rounded-full bg-yellow-600 text-white text-3xl font-bold">
                  {testimonials[activeIndex].name.charAt(0)}
                </div>
              </div>
              <div className="flex-1 text-center md:text-left">
                <div className="flex justify-center md:justify-start mb-3">
                  {renderStars(testimonials[activeIndex].rating)}
                </div>
                <p className="text-xl md:text-2xl text-gray-100 italic mb-6">
                  "{testimonials[activeIndex].feedback}"
                </p>
                <div>
                  <h3 className="text-xl font-bold text-white">{testimonials[activeIndex].name}</h3>
                  <p className="text-yellow-400">{testimonials[activeIndex].position}</p>
                  <p className="text-sm text-gray-400 mt-1">Project: {testimonials[activeIndex].project}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-8">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => {
                setIsAnimating(true);
                setTimeout(() => {
                  setActiveIndex(index);
                  setIsAnimating(false);
                }, 500);
              }}
              className={`w-3 h-3 mx-1 rounded-full transition-all duration-300 ${
                activeIndex === index ? "bg-yellow-600 w-6" : "bg-gray-600 hover:bg-gray-500"
              }`}
              aria-label={`Go to testimonial ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
