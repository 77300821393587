import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaVimeo, FaLinkedinIn, FaTiktok } from "react-icons/fa";
import { MdLocationOn, MdPhone, MdEmail, MdKeyboardArrowRight } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400">
      {/* Main Footer */}
      <div className="container mx-auto px-6 pt-16 pb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-2xl font-bold text-white mb-4">
              Energy Film Production
            </h3>
            <p className="mb-6 text-gray-400 pr-4">
              Creating cinematic excellence with passion. We bring stories to life with creativity, technology, and artistic vision.
            </p>
            <div className="flex space-x-4 mb-6">
              <a
                href="https://www.instagram.com/energy_film_production/"
                className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center text-white hover:bg-yellow-600 transition-all duration-300"
              >
                <FaInstagram size={16} />
              </a>
              <a
                href="https://www.tiktok.com/@energy_production"
                className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center text-white hover:bg-yellow-600 transition-all duration-300"
              >
                <FaTiktok size={16} />
              </a>
              <a
                href="https://www.youtube.com/@EnergyFilmProduction-nq3fx"
                className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center text-white hover:bg-yellow-600 transition-all duration-300"
              >
                <FaYoutube size={16} />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-xl font-semibold text-white mb-4 relative pl-4 before:content-[''] before:absolute before:left-0 before:top-0 before:h-full before:w-1 before:bg-yellow-600">
              Quick Links
            </h4>
            <ul className="space-y-2">
              {["Home", "About Us", "Services", "Portfolio", "Our Team", "Blog", "Contact Us", "Careers"].map((item) => (
                <li key={item} className="transition-all duration-300 hover:text-white">
                  <a href="#" className="flex items-center">
                    <MdKeyboardArrowRight className="mr-2 text-yellow-500" />
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Services */}
          <div>
            <h4 className="text-xl font-semibold text-white mb-4 relative pl-4 before:content-[''] before:absolute before:left-0 before:top-0 before:h-full before:w-1 before:bg-yellow-600">
              Our Services
            </h4>
            <ul className="space-y-2">
              {[
                "Commercial Production",
                "Music Video Production",
                "Short Film Production",
                "Documentary Filmmaking",
                "Corporate Videos",
                "Event Videography",
                "Animation & Motion Graphics",
                "Post-Production Services"
              ].map((item) => (
                <li key={item} className="transition-all duration-300 hover:text-white">
                  <a href="#" className="flex items-center">
                    <MdKeyboardArrowRight className="mr-2 text-yellow-500" />
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h4 className="text-xl font-semibold text-white mb-4 relative pl-4 before:content-[''] before:absolute before:left-0 before:top-0 before:h-full before:w-1 before:bg-yellow-600">
              Contact Us
            </h4>
            <ul className="space-y-4">
              <li className="flex items-start">
                <MdLocationOn className="text-yellow-500 text-xl mr-3 mt-1" />
                <p>Around Yolly, Addis Ababa</p>
                
              </li>
              <li className="flex items-center">
                <MdPhone className="text-yellow-500 text-xl mr-3" />
                <p>+251-91 181 5739</p>
              </li>
              <li className="flex items-center">
                <MdEmail className="text-yellow-500 text-xl mr-3" />
                <p>info@energyfilmproduction.com</p>
              </li>
            </ul>
            
            <div className="mt-6">
              <h5 className="text-white font-medium mb-2">Subscribe to our newsletter</h5>
              <div className="flex">
                <input 
                  type="email" 
                  placeholder="Your email" 
                  className="p-2 bg-gray-800 border border-gray-700 rounded-l-md focus:outline-none focus:ring-1 focus:ring-yellow-500 w-full text-sm"
                />
                <button className="bg-yellow-600 hover:bg-yellow-700 text-white px-4 rounded-r-md transition-colors">
                  <MdKeyboardArrowRight size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Divider */}
      <div className="border-t border-gray-800">
        <div className="container mx-auto px-6 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm">
              &copy; {new Date().getFullYear()} Energy Film Production. All Rights Reserved.
            </p>
            <div className="mt-4 md:mt-0">
              <ul className="flex space-x-6">
                <li><a href="#" className="text-sm hover:text-white transition-colors">Privacy Policy</a></li>
                <li><a href="#" className="text-sm hover:text-white transition-colors">Terms of Service</a></li>
                <li><a href="#" className="text-sm hover:text-white transition-colors">Cookie Policy</a></li>
                <li><a href="#" className="text-sm hover:text-white transition-colors">Sitemap</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    
    </footer>
  );
};

export default Footer;
