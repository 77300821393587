import React from "react";
import PortfolioSection from "../Components/PortfolioSection";

const Portfolio = () => {
  return (
    <div>
      <PortfolioSection  limit={12}/>
    </div>
  );
};

export default Portfolio;
