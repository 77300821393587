import React from "react";
import Hero from "../Components/Hero";
import AboutSection from "../Components/AboutSection";
import ServicesSection from "../Components/ServicesSection";
import PortfolioSection from "../Components/PortfolioSection";
import TestimonialsSection from "../Components/TestimonialsSection";
import ContactSection from "../Components/ContactSection";

const Home = () => {
  return (
    <div>
      <Hero />
      <ServicesSection />
      <AboutSection />
      <PortfolioSection limit={6} />
      <TestimonialsSection />
      <ContactSection />
    </div>
  );
};

export default Home;
