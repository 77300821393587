import React, { useState, useEffect, useRef } from "react";
import { Award, Linkedin, Twitter, Mail, ChevronRight } from "lucide-react";

const MeetCEOSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.2 }
    );
    
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  // CEO achievements
  const achievements = [
    "Founded Energy Film Production in 2013",
    "Recognized for Outstanding Direction in Ethiopian Cinema",
    "Directed 15+ nationally recognized commercials",
    "Executive Producer on 3 feature-length documentaries",
    "Industry speaker on innovative storytelling"
  ];

  return (
    <section ref={sectionRef} className="relative py-28 bg-gray-950 text-white overflow-hidden">
      <div className="absolute inset-0 opacity-20">
        <div className="absolute top-0 right-0 w-1/3 h-1/2 bg-gradient-to-bl from-blue-600 to-transparent blur-3xl"></div>
        <div className="absolute bottom-0 left-0 w-1/3 h-1/2 bg-gradient-to-tr from-cyan-600 to-transparent blur-3xl"></div>
      </div>
      
      <div className="container mx-auto px-6">
        {/* Section heading with animated underline */}
        <div className="text-center mb-16">
          <div className="inline-block relative">
            <h2 className={`text-4xl md:text-5xl font-bold mb-3 transition-all duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0 translate-y-6'}`}>
              Meet Our <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-500">CEO</span>
            </h2>
            <div className={`absolute -bottom-2 left-1/2 transform -translate-x-1/2 h-1 bg-blue-500 transition-all duration-1500 ease-out ${isVisible ? 'w-24' : 'w-0'}`}></div>
          </div>
          <p className={`text-lg md:text-xl max-w-3xl mx-auto mt-8 text-gray-300 transition-all duration-1000 delay-300 ${isVisible ? 'opacity-100' : 'opacity-0 translate-y-6'}`}>
            The visionary leader behind Energy Film Production, bringing decades of creative excellence
            and industry expertise to every project we undertake.
          </p>
        </div>
        
        {/* Main content with two columns - reversed from About section */}
        <div className="grid md:grid-cols-2 gap-16 items-center">
          {/* Left column - Content */}
          <div className={`transition-all duration-1000 delay-500 ${isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-12'}`}>
            <h3 className="text-3xl font-bold mb-4 text-white">Daniel Girma</h3>
            <p className="text-xl text-blue-400 mb-6">Founder & Chief Executive Officer</p>
            
            <div className="space-y-6 text-gray-300">
              <p>
                With over 15 years of experience in film and production, Daniel has built Energy Film 
                from a small passion project into an award-winning creative powerhouse known for its 
                unique visual style and powerful storytelling.
              </p>
              <p>
                Daniel's approach combines technical innovation with creative vision, pushing the boundaries 
                of what's possible in modern filmmaking while staying true to the emotional core of every story.
              </p>
              
              {/* CEO's philosophy quote */}
              <div className="my-8 pl-4 border-l-4 border-blue-500 italic text-white">
                "I believe that every project has a beating heart—a truth waiting to be uncovered and shared. 
                Our job is to find that truth and bring it to life in the most compelling way possible."
              </div>
              
              {/* Achievements list */}
              <div className="mt-8">
                <h4 className="text-xl font-medium mb-4 text-white">Career Highlights</h4>
                <ul className="space-y-3">
                  {achievements.map((achievement, index) => (
                    <li key={index} className="flex items-center group">
                      <ChevronRight size={16} className="text-blue-500 mr-2 transition-transform group-hover:translate-x-1" />
                      <span className="group-hover:text-white transition-colors">{achievement}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              {/* Social links */}
              <div className="flex space-x-4 mt-8">
                <a href="#" className="p-3 bg-blue-500/20 rounded-full text-blue-400 hover:bg-blue-500/30 transition-colors">
                  <Linkedin size={20} />
                </a>
                <a href="#" className="p-3 bg-blue-500/20 rounded-full text-blue-400 hover:bg-blue-500/30 transition-colors">
                  <Twitter size={20} />
                </a>
                <a href="#" className="p-3 bg-blue-500/20 rounded-full text-blue-400 hover:bg-blue-500/30 transition-colors">
                  <Mail size={20} />
                </a>
              </div>
            </div>
          </div>
          
          {/* Right column - Image */}
          <div className={`relative transition-all duration-1000 delay-300 ${isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-12'}`}>
            <div className="relative">
              {/* Main image */}
              <div className="relative z-10 rounded-lg overflow-hidden shadow-2xl transform -rotate-1 transition-transform duration-500 hover:rotate-0">
                <img
                  src="../Danipic.jpeg"
                  alt="Daniel, CEO of Energy Film Production"
                  className="w-full h-auto object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-60"></div>
              </div>
              
              {/* Floating badge */}
              <div className="absolute -bottom-6 right-12 bg-white text-gray-900 py-2 px-4 rounded-lg shadow-xl z-20 text-sm font-bold flex items-center">
                <Award className="text-blue-500 mr-2" size={16} />
                Industry Innovator
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetCEOSection;
