import React from "react";

const NotFound = () => {
  return (
    <div className="h-screen flex justify-center items-center bg-gray-100">
      <div className="text-center">
        <h1 className="text-5xl font-bold text-red-600">404</h1>
        <p className="text-xl mt-4 text-gray-800">Oops! Page Not Found.</p>
        <p className="mt-2 text-gray-600">
          The page you are looking for doesn't exist or has been moved.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
