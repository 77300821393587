import React, { useState, useEffect, useRef } from "react";
import { Film, Camera, Award, Users, ChevronRight } from "lucide-react";

const AboutSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const stats = [
    { icon: <Film />, value: "150+", label: "Projects Completed" },
    { icon: <Award />, value: "10", label: "Industry Awards" },
    { icon: <Users />, value: "20", label: "Creative Experts" },
    { icon: <Camera />, value: "15", label: "Years Experience" }
  ];

  return (
    <section
      ref={sectionRef}
      className="relative py-24 bg-gray-900 text-white overflow-hidden"
    >
      <div className="container mx-auto lg:px-16 px-6">
        <div className="text-center mb-20">
          <div className="inline-block relative">
            <h2
              className={`text-4xl md:text-5xl font-bold mb-6 transition-all duration-1000 ${isVisible ? "opacity-100" : "opacity-0 translate-y-6"}`}
            >
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-600">About</span> Energy Film Production
            </h2>
            <div className={`absolute -bottom-2 left-1/2 transform -translate-x-1/2 h-1 bg-yellow-500 transition-all duration-1500 ease-out ${isVisible ? "w-24" : "w-0"}`}></div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-16 items-center">
          <div
            className={`relative transition-all duration-1000 delay-300 ${isVisible ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-12"}`}
          >
            <div className="relative z-10 rounded-lg bg-white overflow-hidden shadow-2xl transform rotate-1 transition-transform duration-500 hover:rotate-0">
              <img
                src="../Energy Logo_00000.png"
                alt="Energy Film Production Team"
                className="lg:w-[500px] lg:h-[500px] w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-60"></div>
            </div>
          </div>

          <div className={`transition-all duration-1000 delay-500 ${isVisible ? "opacity-100 translate-x-0" : "opacity-0 translate-x-12"}`}>
            <h3 className="text-3xl font-semibold mb-4 text-yellow-400">Our Commitment to Filmmaking</h3>
            <div className="space-y-6 text-gray-300 leading-relaxed">
              <p className="text-lg">
                Energy Film Production is an Ethiopian film production company dedicated to delivering high-quality content with full commitment to its clients. Renowned for our collaboration with content production companies, directors, cinematographers, editors, music artists, and young talents, we are committed to elevating the local film industry.
              </p>
              <p className="text-lg">
                We leverage the latest technology and cutting-edge techniques to ensure that every aspect of your project is executed with precision and excellence.
              </p>
              <div className="mt-8">
                <h4 className="text-xl font-medium mb-4 text-white">Our Expertise</h4>
                <ul className="space-y-3">
                  {["Cinematic Storytelling", "Visual Effects", "Commercial Production", "Documentary Filmmaking", "Branded Content"].map(
                    (item, index) => (
                      <li key={index} className="flex items-center group">
                        <ChevronRight size={16} className="text-yellow-500 mr-2 transition-transform group-hover:translate-x-1" />
                        <span className="group-hover:text-white transition-colors">{item}</span>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={`mt-20 grid grid-cols-2 md:grid-cols-4 gap-8 transition-all duration-1000 delay-700 ${isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-12"}`}>
          {stats.map((stat, index) => (
            <div key={index} className="bg-black/30 backdrop-blur-sm rounded-lg p-6 text-center transform transition-transform hover:scale-105">
              <div className="flex justify-center mb-4">
                <div className="p-3 bg-yellow-500/20 rounded-full text-yellow-400">
                  {stat.icon}
                </div>
              </div>
              <div className="text-3xl font-bold text-white mb-1">{stat.value}</div>
              <div className="text-gray-400">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
