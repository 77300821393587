import React from "react";
import TestimonialsSection from "../Components/TestimonialsSection";

const Testimonials = () => {
  return (
    <div >
      <TestimonialsSection />
    </div>
  );
};

export default Testimonials;
