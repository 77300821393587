import React from "react";
import ContactSection from "../Components/ContactSection";

const Contact = () => {
  return (
    <div >
      <ContactSection  />
    </div>
  );
};

export default Contact;
