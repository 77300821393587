import React, { useState } from "react";

const projects = [
  { title: "Dagim Tesfaye - Alwedeshem", image: "../alwedeshem.jpg",  category: "Music Video", year: "2021" },
  { title: "Enja - Addis Legesse", image: "../enja.jpg", category: "Music Video", year: "2022" },
  { title: "Yiwezewzegnal - Ayu Girma", image: "../ayu.jpg", category: "Music Video", year: "2021" },
  { title: "Hidar", image: "../hidar.jpg",  category: "Movie", year: "2021" },
  { title: "Geragere", image: "../Geragere.jpg",  category: "Music Video", year: "2023" },
  { title: "Sinebet", image: "../Senbet.jpg", category: "Movie", year: "2021" },
  { title: "Darem Yelew - Ali Birra & Abreham Belayneh", image: "../darm.jpg",  category: "Music Video", year: "2021" },
  { title: "Enjori - Addis Leggesse", image: "../enjori.jpg", category: "Music Video", year: "2021" },
  { title: "Rahel Getu - Etemete", image: "../Etemete.jpg",  category: "Music Video", year: "2021" },
  { title: "Rahel Getu - Tilobign", image: "../telobige.jpg", category: "Music Video", year: "2019" },
  { title: "Solome Show", image: "../solome.jpg",  category: "TV Show", year: "2020" },
  { title: "Fim Nehe - Yeabsira Tadesse", image: "../maxresdefault.jpg",  category: "Music Video", year: "2022" },
];

const PortfolioSection = ({ limit }) => {
  return (
    <section className="py-32 bg-gray-950 text-white relative">
      {/* Background gradients */}
      <div className="absolute inset-0 opacity-30 overflow-hidden">
        <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-gradient-to-br from-blue-800 to-transparent blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-gradient-to-tl from-cyan-800 to-transparent blur-3xl"></div>
      </div>
      
      <div className="container mx-auto px-6 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold mb-4">Our Portfolio</h2>
          <div className="w-24 h-1 bg-gradient-to-r from-blue-500 to-cyan-400 mx-auto mb-8"></div>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Showcasing our finest work across various media formats, each project 
            reflects our commitment to creative excellence and storytelling.
          </p>
        </div>

        {/* Projects Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.slice(0, limit).map((project, index) => (
            <div
              key={index}
              className="group relative overflow-hidden bg-gradient-to-br from-blue-950 to-gray-900 rounded-lg shadow-xl transform transition-all duration-500 hover:scale-105 border border-blue-900/30"
            >
              <div className="relative h-64 overflow-hidden">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover transition-all duration-700 group-hover:scale-110"
                />
              </div>

              <div className="p-6">
                <div className="flex justify-between items-center mb-3">
                  <span className="text-sm text-cyan-400 font-medium">{project.category}</span>
                  <span className="text-sm text-gray-400">{project.year}</span>
                </div>
                <h3 className="text-xl font-bold mb-2 group-hover:text-blue-400 transition-colors">{project.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
