import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, X, Film, Phone, ChevronRight, Facebook, Instagram, Twitter, Youtube, ArrowUp } from "lucide-react";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState("/home");
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      // Show scroll-to-top button when user scrolls down
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Get current path for active link highlighting
  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Social media links
  const socialMediaLinks = [
    { icon: <FaInstagram size={18} />, url: "https://www.instagram.com/energy_film_production/" },
    { icon: <FaTiktok size={18} />, url: "https://www.tiktok.com/@energy_production" },
    { icon: <FaYoutube size={18} />, url: "https://www.youtube.com/@EnergyFilmProduction-nq3fx" },
  ];

  return (
    <>
      <header
        className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
          scrolled
            ? "bg-blue-950/90 backdrop-blur-md py-4 shadow-lg"
            : "bg-gradient-to-r from-blue-950/70 to-blue-900/50 backdrop-blur-sm py-3 sm:py-4"
        }`}
      >
        <div className="container mx-auto flex justify-between items-center px-4 sm:px-6">
          {/* Logo with animation */}
          <Link
            to="/"
            className="flex items-center group"
            onClick={() => setActiveLink("/")}
          >
            <Film
              size={24}
              className={`text-amber-500 mr-2 transition-all duration-300 ${
                scrolled ? "scale-90" : "scale-100"
              } group-hover:animate-spin-slow group-hover:text-amber-400`}
            />
            <div className="flex flex-col">
              <span className={`font-bold tracking-wider transition-all duration-300 ${
                scrolled ? "text-base sm:text-xl" : "text-lg sm:text-2xl"
              } text-white group-hover:text-amber-400`}>
                ENERGY
              </span>
              <span className="text-[10px] sm:text-xs text-gray-300 tracking-widest -mt-1 group-hover:text-white">
                FILM PRODUCTION
              </span>
            </div>
          </Link>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-white focus:outline-none"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle menu"
          >
            {isOpen ? <X size={24} className="text-amber-400" /> : <Menu size={24} />}
          </button>

          {/* Desktop Menu */}
          <nav className="hidden md:flex items-center space-x-1 lg:space-x-2">
            {[
              { name: "Home", path: "/home" },
              { name: "About", path: "/about" },
              { name: "Services", path: "/services" },
              { name: "Portfolio", path: "/portfolio" },
              { name: "Contact", path: "/contact" },
            ].map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className={`px-3 lg:px-4 py-2 text-base lg:text-lg font-medium relative group transition ${
                  activeLink === item.path 
                    ? "text-amber-400" 
                    : "text-white hover:text-amber-300"
                }`}
                onClick={() => setActiveLink(item.path)}
              >
                <span className="relative z-10 transition-colors duration-300">{item.name}</span>
                <span className={`absolute bottom-0 left-0 h-0.5 bg-amber-500 transition-all duration-300 ${
                  activeLink === item.path ? "w-full" : "w-0 group-hover:w-full"
                }`}></span>
              </Link>
            ))}

            {/* Call Us Button */}
            <a
              href="tel:+1234567890" // Replace with actual phone number
              className="ml-2 lg:ml-4 px-4 lg:px-6 py-1.5 lg:py-2 rounded-full bg-gradient-to-r from-yellow-600 to-yellow-500 text-white text-sm lg:text-base font-medium 
              hover:shadow-lg hover:shadow-amber-500/30 hover:scale-105 transition-all duration-300 flex items-center group"
            >
              <Phone size={16} className="mr-2 group-hover:animate-pulse" />
              <span>Call Us</span>
            </a>
          </nav>
        </div>

        {/* Mobile Menu (Slide In Effect with backdrop) */}
        {isOpen && (
          <div
            className="fixed inset-0 bg-black/40 backdrop-blur-sm z-40 md:hidden"
            onClick={() => setIsOpen(false)}
          ></div>
        )}
        <div
          className={`fixed top-0 right-0 h-screen w-64 sm:w-72 bg-gradient-to-b from-blue-950 to-blue-900 p-5 sm:p-6 transform transition-all duration-500 ease-in-out z-50 ${
            isOpen ? "translate-x-0 shadow-2xl shadow-amber-500/10" : "translate-x-full"
          } md:hidden overflow-y-auto`}
        >
          <button
            className="absolute top-4 right-4 text-white hover:text-amber-400 transition"
            onClick={() => setIsOpen(false)}
            aria-label="Close menu"
          >
            <X size={24} />
          </button>

          {/* Mobile Logo */}
          <Link to="/" className="flex items-center mt-3" onClick={() => {
            setIsOpen(false);
            setActiveLink("/");
          }}>
            <Film size={22} className="text-amber-500 mr-2" />
            <span className="text-lg font-bold text-white">ENERGY</span>
          </Link>

          <nav className="mt-10 space-y-1">
            {[
              { name: "Home", path: "/home" },
              { name: "About", path: "/about" },
              { name: "Services", path: "/services" },
              { name: "Portfolio", path: "/portfolio" },
              { name: "Contact", path: "/contact" },
            ].map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className={`flex items-center w-full px-4 py-2.5 text-base font-medium rounded group transition-all duration-300 ${
                  activeLink === item.path 
                    ? "bg-amber-500/20 text-amber-400" 
                    : "text-white hover:bg-white/10"
                }`}
                onClick={() => {
                  setIsOpen(false);
                  setActiveLink(item.path);
                }}
              >
                <span>{item.name}</span>
                <ChevronRight 
                  size={16} 
                  className={`ml-auto transition-transform duration-300 ${
                    activeLink === item.path ? "text-amber-400 opacity-100" : "opacity-0 group-hover:opacity-100 text-gray-400"
                  }`} 
                />
              </Link>
            ))}

            {/* Mobile Call Us Button */}
            <a
              href="tel:+251-911815739" // Replace with actual phone number
              className="flex items-center justify-center w-full mt-4 px-5 py-2.5 rounded-lg bg-gradient-to-r from-yellow-600 to-yellow-500 text-white font-medium shadow-lg shadow-amber-500/20 hover:shadow-amber-500/30 hover:brightness-110 transition-all"
              onClick={() => setIsOpen(false)}
            >
              <Phone size={16} className="mr-2 animate-pulse" />
              <span>Call Us</span>
            </a>
          </nav>

          {/* Social Media Icons in Mobile Menu */}
          <div className="absolute bottom-8 left-0 w-full px-6">
            <div className="flex justify-center space-x-4">
              {socialMediaLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-8 h-8 flex items-center justify-center rounded-full bg-white/10 text-white hover:bg-amber-500 hover:text-white transition-all duration-300 hover:scale-110"
                  aria-label={social.name}
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </header>

      {/* Scroll-to-Top Button */}
      {showScrollToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-6 right-6 z-[9999] p-3 bg-amber-500 rounded-full shadow-lg hover:bg-amber-600 transition-all duration-300 hover:scale-110"
          aria-label="Scroll to top"
        >
          <ArrowUp size={20} className="text-white" />
        </button>
      )}
    </>
  );
};

export default Header;