import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Play, ChevronDown, Star, Film } from "lucide-react";

const Hero = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Handle scroll effect for parallax
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Set initial loaded state after a brief delay
    const loadTimer = setTimeout(() => {
      setIsLoaded(true);
    }, 300);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(loadTimer);
    };
  }, []);

  const parallaxOffset = scrollPosition * 0.4;

  // Video reel items with additional metadata
  const videoProjects = [
    { type: "Commercial", color: "text-yellow-400" },
    { type: "Documentary", color: "text-amber-400" },
    { type: "Music Video", color: "text-yellow-400" },
    { type: "Film", color: "text-amber-400" },
  ];

  return (
    <section className="relative w-full h-screen overflow-hidden">
      {/* Background video or image with parallax effect */}
      <div
        className="absolute inset-0 w-full h-full bg-blue-950"
        style={{
          transform: `translateY(${parallaxOffset}px)`,
          transition: "transform 0.1s ease-out",
        }}
      >
        {/* Fallback image with parallax effect */}
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: "url('/hero-bg.jpg')",
            filter: "brightness(0.6) contrast(1.1)",
          }}
        ></div>

        {/* Cinematic overlay gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-blue-950/70 via-blue-900/40 to-blue-950/90"></div>

        {/* Animated diagonal lines overlay */}
        <div className="absolute inset-0 bg-gradient-to-br from-transparent to-blue-950/5 pointer-events-none opacity-70">
          <div
            className="absolute inset-0"
            style={{
              backgroundImage:
                "linear-gradient(135deg, transparent 0%, transparent 49%, rgba(255,255,255,0.05) 50%, transparent 51%, transparent 100%)",
              backgroundSize: "4px 4px",
            }}
          ></div>
        </div>

        {/* Cinematic letterbox bars */}
        <div
          className="absolute top-0 left-0 w-full h-8 md:h-12 bg-blue-950 transform origin-top transition-all duration-1000 ease-out"
          style={{
            transform: isLoaded ? "scaleY(1)" : "scaleY(0)",
          }}
        ></div>
        <div
          className="absolute bottom-0 left-0 w-full h-8 md:h-12 bg-blue-950 transform origin-bottom transition-all duration-1000 ease-out"
          style={{
            transform: isLoaded ? "scaleY(1)" : "scaleY(0)",
          }}
        ></div>

        {/* Floating particles effect */}
        {isLoaded &&
          Array.from({ length: 20 }).map((_, i) => (
            <div
              key={i}
              className="absolute w-1 h-1 rounded-full bg-white/20 animate-float-slow"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 10}s`,
                animationDuration: `${15 + Math.random() * 20}s`,
              }}
            ></div>
          ))}
      </div>

      {/* Hero content */}
      <div className="relative container mx-auto px-4 sm:px-6 h-full flex flex-col justify-center items-center text-center">
        {/* Animated star icon */}
        <div
          className={`mb-4 transition-all duration-1000 ${
            isLoaded ? "opacity-100 transform translate-y-0" : "opacity-0 transform -translate-y-8"
          }`}
        >
          <Film
            size={28}
            className="text-yellow-400 animate-pulse"
            fill="rgba(251, 146, 60, 0.2)"
          />
        </div>

        {/* Animated text reveal */}
        <div className="overflow-hidden mb-2">
          <span
            className={`inline-block text-xs sm:text-sm font-medium tracking-widest text-yellow-500 uppercase transform transition duration-1000 ${
              isLoaded ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"
            }`}
          >
            Energy Film Production
          </span>
        </div>

        <div className="overflow-hidden mb-4">
          <h1
            className={`text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-white transform transition duration-700 delay-300 ${
              isLoaded ? "translate-y-0 opacity-100" : "translate-y-1/2 opacity-0"
            }`}
            style={{ textShadow: "0 4px 6px rgba(0,0,0,0.3)" }}
          >
            <span className="block mb-1 sm:mb-2">Capturing Stories,</span>
            <span className="block bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 via-white to-yellow-300">
              Creating Impact
            </span>
          </h1>
        </div>

        <div className="overflow-hidden mb-6 sm:mb-8">
          <p
            className={`text-base sm:text-lg md:text-xl lg:text-2xl max-w-xs sm:max-w-lg md:max-w-2xl mx-auto text-gray-200 transform transition duration-700 delay-500 ${
              isLoaded ? "translate-y-0 opacity-100" : "translate-y-1/2 opacity-0"
            }`}
          >
            We turn your vision into stunning cinematic experiences that resonate with your audience.
          </p>
        </div>

        {/* Buttons with enhanced hover effects */}
        <div
          className={`flex flex-col sm:flex-row justify-center items-center space-y-3 sm:space-y-0 sm:space-x-4 md:space-x-6 transform transition duration-700 delay-700 ${
            isLoaded ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
          } w-full max-w-xs sm:max-w-none`}
        >
          <Link
            to="/portfolio"
            className="group relative overflow-hidden bg-yellow-600 text-white px-6 sm:px-8 py-3 sm:py-4 rounded-full font-medium flex items-center justify-center w-full sm:w-auto min-w-0 sm:min-w-40 hover:shadow-lg hover:shadow-yellow-600/30 transition-all duration-300"
          >
            <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-yellow-700 to-yellow-500 transition-transform duration-300 ease-out transform group-hover:scale-110"></span>
            <span className="absolute inset-0 w-full h-full opacity-0 bg-gradient-to-r from-yellow-700 via-yellow-500 to-yellow-700 transition-opacity duration-300 ease-out group-hover:opacity-100"></span>
            <span className="relative flex items-center">
              <Play size={16} className="mr-2 group-hover:animate-pulse" />
              <span>View Our Work</span>
            </span>
          </Link>

          <Link
            to="/contact"
            className="group relative overflow-hidden border-2 border-yellow-400/80 bg-transparent text-white px-6 sm:px-8 py-3 sm:py-4 rounded-full font-medium flex items-center justify-center w-full sm:w-auto min-w-0 sm:min-w-40 hover:border-yellow-300 hover:shadow-lg hover:shadow-yellow-400/10 transition-all duration-300"
          >
            <span className="absolute inset-0 w-0 bg-yellow-400/10 transition-all duration-300 ease-out transform group-hover:w-full"></span>
            <span className="relative">Get in Touch</span>
          </Link>
        </div>

        {/* Fixed "We create" section */}
        <div
          className={`absolute lg:bottom-28 bottom-24 left-0 w-full transition duration-1000 delay-1000 ${
            isLoaded ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="flex justify-center items-center px-4">
            <div className="flex flex-col sm:flex-row items-center bg-blue-900/50 backdrop-blur-sm px-4 sm:px-6 py-2 sm:py-3 rounded-full border border-blue-600/20 max-w-[90%] sm:max-w-md mx-auto">
              <span className="text-gray-300 text-sm sm:text-base sm:mr-3 whitespace-nowrap">
                We create
              </span>
            
            </div>
          </div>
        </div>

        {/* Scroll indicator */}
        <div
          className={`absolute bottom-8 left-0 w-full flex justify-center transition duration-1000 delay-1500 ${
            isLoaded ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className="w-10 h-10 border-2 border-white rounded-full flex justify-center items-center cursor-pointer animate-bounce"
            onClick={() => window.scrollTo({ top: window.innerHeight, behavior: "smooth" })}
          >
            <ChevronDown className="text-white" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;