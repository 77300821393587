import React, { useState, useEffect, useRef } from "react";
import { Film, Camera, Video, Edit, Clapperboard, Music, Users, Monitor, Lightbulb, Brush, ClipboardCheck, Scissors } from "lucide-react";

const Services = () => {
  const [activeService, setActiveService] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const services = [
    { 
        title: "Concept Development", 
        description: "We specialize in developing creative concepts for commercials, music videos, short films, and documentaries, ensuring unique and impactful storytelling for every project.", 
        icon: <Lightbulb className="w-10 h-10 text-blue-400" />, 
        details: [
            "Creative Concept Ideation",
            "Market & Audience Research",
            "Script & Story Development",
            "Brand & Theme Integration"
        ]
    },
    { 
        title: "Design and Development", 
        description: "Our team excels in mockup development and 2D sketch design, turning creative ideas into visually compelling and functional digital assets for various media projects.", 
        icon: <Brush className="w-10 h-10 text-green-400" />, 
        details: [
            "Mockup & Wireframe Creation",
            "2D & 3D Visual Conceptualization",
            "Scene & Character Design",
            "User Experience Enhancement"
        ]
    },
    { 
        title: "Script Development", 
        description: "We provide full script development services, transforming ideas into screen-ready scripts for movies and TV series, including script adaptation and professional writing.", 
        icon: <Edit className="w-10 h-10 text-red-400" />, 
        details: [
            "Screenwriting & Storyboarding",
            "Script Adaptation for Film & TV",
            "Dialogue & Character Development",
            "Final Draft Optimization"
        ]
    },
    { 
        title: "Pre-Production", 
        description: "From location scouting to set design, we ensure smooth pre-production planning, managing every detail to bring creative visions to life efficiently and effectively.", 
        icon: <ClipboardCheck className="w-10 h-10 text-yellow-400" />, 
        details: [
            "Location Scouting & Permits",
            "Casting & Talent Coordination",
            "Production Scheduling & Logistics",
            "Set & Prop Design"
        ]
    },
    { 
        title: "Production", 
        description: "We offer full-scale production services, covering directing, production management, assistant directing, costume design, and make-up artistry to achieve top-quality visual storytelling.", 
        icon: <Camera className="w-10 h-10 text-purple-400" />, 
        details: [
            "Professional Directing & Filming",
            "Production Management",
            "Assistant Directing & Coordination",
            "Costume & Make-Up Design"
        ]
    },
    { 
        title: "Post-Production", 
        description: "Our post-production expertise includes editing, sound design, color grading, LUT creation, and mastering, ensuring polished and high-quality final video and audio output.", 
        icon: <Scissors className="w-10 h-10 text-orange-400" />, 
        details: [
            "Video Editing & Special Effects",
            "Sound Design & Audio Mixing",
            "Color Grading & LUT Creation",
            "Final Output Optimization"
        ]
    }
];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );
    
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className="relative py-40 bg-gray-950 text-white overflow-hidden">
      <div className="absolute inset-0 opacity-20">
        <div className="absolute top-0 right-0 w-1/3 h-1/2 bg-gradient-to-bl from-blue-600 to-transparent blur-3xl"></div>
        <div className="absolute bottom-0 left-0 w-1/3 h-1/2 bg-gradient-to-tr from-cyan-600 to-transparent blur-3xl"></div>
      </div>
      
      <div className="container mx-auto px-6 z-10 relative">
        <div className="text-center mb-16">
          <h2 className={`inline-block text-4xl md:text-5xl font-bold mb-4 relative transition-all duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0 -translate-y-8'}`}>
            <span className="text-white">Our Services</span>
            <span className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-transparent via-cyan-500 to-transparent"></span>
          </h2>
          <p className={`text-xl max-w-3xl mx-auto mt-6 text-gray-300 transition-all duration-1000 delay-300 ${isVisible ? 'opacity-100' : 'opacity-0 translate-y-8'}`}>
            We offer comprehensive film production services tailored to your specific needs,
            from concept development to final delivery.
          </p>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <div 
              key={index}
              className={`relative group transition-all duration-700 transform ${
                isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'
              }`}
              style={{ transitionDelay: `${150 * (index % 4)}ms` }}
              onMouseEnter={() => setActiveService(index)}
              onMouseLeave={() => setActiveService(null)}
            >
              <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-blue-950 to-gray-900 h-full transition-all duration-300 group-hover:shadow-lg group-hover:shadow-blue-500/10 border border-blue-900/40 group-hover:border-blue-800/60">
                <div className="p-6 sm:p-8 z-10 relative h-full flex flex-col">
                  <div className={`w-16 h-16 rounded-lg mb-6 flex items-center justify-center bg-gradient-to-br ${service.color} text-white shadow-md`}>
                    {service.icon}
                  </div>
                  
                  <h3 className="text-xl sm:text-2xl font-bold mb-3 text-white group-hover:text-cyan-400 transition-colors">
                    {service.title}
                  </h3>
                  
                  <p className="text-gray-400 mb-6 flex-grow text-sm sm:text-base">
                    {service.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

      </div>
    </section>
  );
};

export default Services;